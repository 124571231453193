//Breakpoints 
$layout-breakpoints-large: 1920px; 
$layout-breakpoints-medium: 1440px; 
$layout-breakpoints-small: 960px; 
$layout-breakpoint-xsmall: 480px; 

//Color
$background-color: #F7F7F7; 
$black: #000000; 
$pink: #FFB49D; 
$yellow: #FFD801;
$logo-blue:  #006383;

