@import url('https://fonts.googleapis.com/css2?family=Amarante&family=Darker+Grotesque:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');


//font-family
$Amarante: 'Amarante', cursive;
$DarkGrotesque: 'Darker Grotesque', sans-serif;
$LibreBasker: 'Libre Baskerville', serif;

h1 {
    font-family: $LibreBasker;
    font-weight: 100;
    margin: 1vw 0;
    @media screen and (min-width: $layout-breakpoints-medium) {
        font-size: 4rem;
    }
    @media screen and (min-width: $layout-breakpoints-small) and 
    (max-width: $layout-breakpoints-medium) {
        font-size: 5vw;
        margin: 2vh 0;
    }
    @media screen and (max-width: $layout-breakpoint-xsmall) {
        font-size: 8vw;
        margin: 2vh 0;
    }
    @media screen and (min-width: $layout-breakpoint-xsmall) and 
    (max-width: $layout-breakpoints-small) {
        font-size: 6vw;
        margin: 2vh 0;
    }
    @media (max-height: 40rem) {
        font-size: 4.5vh;
    }
}

h2 {
    font-family: $LibreBasker;
    font-size: 2.75rem;
    font-weight: 100;
    margin: 0;
    @media (max-height: 20rem) and (min-width: $layout-breakpoints-small){
        font-size: 2vw;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 9vw;
        margin: 2vh 0;
    }

    @media screen and (max-width: 1280px) {
        font-size: 2rem;
    }

    @media screen and (min-width: $layout-breakpoints-small) and (max-width: 1460px) and (max-height: 45rem) {
        font-size: 2rem;
    }
}

h3 {
    font-family: $LibreBasker;
    font-size: 2vw;
    font-weight: 100;
    @media (max-height: 20rem) and (min-width: $layout-breakpoints-small){
        font-size: 1vw;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 2.25rem;
        margin: 0;
    }
}

h4 {
    font-family: $DarkGrotesque;
    margin: 0;
    @media (max-height: 20rem) and (min-width: $layout-breakpoints-small){
        font-size: 1vw;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 1.2rem;
        margin-top: 0.5em;
    }
}



h5 {
    font-family: $LibreBasker;
    font-weight: 100;
    font-size: 1.25rem;
    font-weight: 100;
    margin-bottom: 1em;
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 2.5rem;
        padding: 0.5em;
        margin: 0;;
        box-sizing: border-box;
        border-bottom: 1px solid $black;
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        width: 100%;
        text-align: center;
    }
}

h6 {
    font-family: $LibreBasker;
    font-weight: 100;
    font-size: 1.25rem;
    font-weight: 100;
    margin-bottom: 1em;
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 1.5rem;
        margin: 0;;
        box-sizing: border-box;
    }
}

span {
    font-size: 2rem;
    font-family: $LibreBasker;
}

p {
    font-size: 0.875rem;
    font-family: $DarkGrotesque;
    font-weight: 800;
    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 1.1rem;
    }
}

a {
    font-size: 0.875rem;
    font-family: $DarkGrotesque;
    font-weight: 600;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;

    &:visited {
        color: $black;
    }
}