.frame-about { 
    width: 100vw;
    height: calc(100vh - 5.8rem);
    box-sizing: border-box;
    display: flex;
    padding-right: 4.25em;
    border-right: 1px solid $black;

    @media screen and (max-width: $layout-breakpoints-small) {
        flex-direction: column;
        height: auto;
        padding: 7em 2.125em 2.125em 2.125em;
        border: 0;
    }
}

.about-description {
    padding: 6em 4.25em 0 4.25em;
    width: 40%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media (max-height: 40rem) {
        padding: 2vh 4.25em;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        width: 100%;
        padding: 0;
    }
}

.about-p {
    width: 20%;
    border-left: 1px solid $black;
    box-sizing: border-box;
    padding: 0 1.5em 4.25em 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-height: 40rem) and (min-width: $layout-breakpoints-small){
        padding: 0 1.5em;
        

    }
    @media screen and (max-width: $layout-breakpoints-small) {
        width: 100%;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 4em 0 0 0;
    }
}


.smallimg-about {
    width: 40%;
    @media (max-height: 20rem) and (min-width: $layout-breakpoints-small){
        width: 80px;
        height: 80px;
    }
}

.eye-about {
    transform: rotate(23deg);
}

.content-box-about {
    box-sizing: border-box;
    height: 35%;
    @media (max-height: 40rem) and (min-width: $layout-breakpoints-small){
        display: none;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        padding: 1em ;
        border-left: 1px solid $black;
        border-right: 1px solid $black;
    }
    @media screen and (min-width: $layout-breakpoints-small)
    and (max-width: 1100px) {
        height: 50%;
    }
}

.text-about {
    font-weight: 500;
    margin: 0;
}