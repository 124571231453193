@import "../abstracts/variables";

.header {
    position: fixed;
    width: 100vw;
    z-index: 2;
}

.nav-bar {
    border-bottom: 1.5px solid $black;
    padding: 1.5em 4.25em;
    display: flex;
    justify-content: space-between;
    background : #f7f7f7;
    @media screen and (max-width: $layout-breakpoints-small) {
        padding: 0.7em 2.125em;
    }
}


.home-logo {
    cursor: pointer;
}

.menuIcon {
    position: relative; 
    width: 46px; 
    height: 46px; 
    cursor: pointer;
    z-index: 5; 

    .menuIcon__checkbox {
        display: block; 
        width: 100%; 
        height: 100%;
        position: relative; 
        cursor: pointer; 
        z-index: 2; 
        -webkit-touch-callout: none; 
        position: absolute; 
        opacity: 0;
        }
    div {
        margin: auto; 
        position: absolute; 
        top: 0; 
        right: 0; 
        left: 0;
        bottom: 0; 
        width: 22px; 
        height: 12px; 
        }
    span {
        position: absolute;
        display: block; 
        width: 100%; 
        height: 3px; 
        background-color: $pink;
        border: 1px solid $black;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
            top: 0; 
        }

        &:last-of-type {
            bottom: 0; 
        }
    }


    

    // &.active:hover span:first-of-type, 
    // &.active:hover span:last-of-type,
    // &:hover .menu-icon__checkbox:checked 
    // + div span:first-of-type,
    // &:hover .menu-icon__checkbox:checked
    // + div span:last-of-type {
    //     width: 22px; 
    // }

    // &:hover {
    //     @media (min-width: 1024px) {
    //         span:first-of-type {
    //             width: 26px;
    //         }

    //         span:last-of-type {
    //             width: 12px;
    //         }
    //     }
    // }

}

.wrapper {
    z-index: 2;
    position: fixed; 
    top: 0; 
    right: 0%; 
    height: 100vh; 
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    background : #f7f7f7;

}

.menu-item {
    overflow: hidden;
    position: relative; 
    display: inline-block;
    margin: 1.5em 0 0 0;

    &:after {
        content: '';
        position: absolute; 
        width: 100%; 
        left:0;
    }


    &:hover::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
    }

    &:after {
        content: attr(data-replace);
        transform-origin: 100% 50%;
        transform: translate3d(0, 100%, 0);
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        font-size: 2rem;
        font-family: $LibreBasker;
    }
    
    &:hover::after {
        transform: translate3d(0, 0, 0);
    }

    span {
        color: $black;
        cursor: pointer;
        display: inline-block;
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        
    } 

    &:hover span {
        transform: translate3d(0, -100%, 0);
    }

}