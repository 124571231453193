.frame-works { 
    width: 100vw;
    height: calc(100vh - 5.8rem);
    box-sizing: border-box;
    display: flex;
    padding: 2.125em 4.25em ;
    gap: 0.85rem;
    border-right: 1px solid $black;

    @media screen and (max-width: $layout-breakpoints-small) {
        padding: 7em 2.125em 0 2.125em;
        border-right: 0;
        height: auto;
        flex-direction: column;
        width: 100vw;
    }

    .image-works {
        width: 50%;
        display: flex;
        justify-content: center;
        border-radius: 1rem;;
    }
}

//work 
.wrapper-work {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $layout-breakpoints-small) {
        width: 100%;
        border: 0;
        height: auto;
        margin-bottom: 3em;
    }
}

//work-upper

.work-image-block {
    position: relative;
    @media screen and (max-width: $layout-breakpoints-small) {
        order: 0;
        margin-bottom: 2em;
    }
    @media screen and (min-width: $layout-breakpoints-small) and (max-width: 1460px) {
        height: 60%;
    }
    @media (max-height: 40rem) {
        display: none;
    }
}

.work-highlights {
    position: absolute;
    font-size: 0.875rem;
    font-family: $DarkGrotesque;
    font-weight: 600;
    background-color: $yellow;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    letter-spacing: .2rem;
    right: -10px;
    bottom: -10px;
    z-index: 0;
    @media screen and (max-width: $layout-breakpoints-small) {
        display: none;
    }
}

.work-image {
    width: 90%; 
    object-fit: cover;
    z-index: 0;
    display: none;

    @media (max-height: 40rem) {
        display: none;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        display: block;
        width: calc(100% + 4.25em);
        margin: 1em -2.125em 0;
    }
    &-desktop {
        width: 100%;
        object-fit: contain;
        z-index: 0;
        @media screen and (max-width: $layout-breakpoints-small) {
            display: none;
        }
    }
}

.work-tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: $layout-breakpoints-small) {
        justify-content: flex-start;
        order: 2;
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        padding: 1.8em 0.5em;
    }
    @media screen and (min-width: $layout-breakpoints-small) and (max-width: 1460px) {
        height: 15%;
        align-items: flex-end;
    }
}

.work-tag {
    border: 1px solid $black;
    padding: 0.5em 1.5em; 
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0.2em;
    text-transform: uppercase;

    @media screen and (max-width: $layout-breakpoints-small) {
        margin: 0.3em;
    }
}

.work-tag-box {
    width: auto;
    @media screen and (max-width: $layout-breakpoints-small) {
        width: 50%;
    }
    @media screen and (min-width: $layout-breakpoints-small) and (max-width: 1460px) {
        width: 50%;
    }
}

//work-lower

// .work-lower {
//     height: 13%;
//     border-top: 1px solid $black;
//     border-bottom: 1px solid $black;
//     display: flex;
//     @media screen and (max-width: $layout-breakpoints-small) {
//         height: 13vh;
//     }
// }



.button-work-nogithub {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $layout-breakpoints-small) {
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        &:first-of-type {
            border-right: 1px solid $black;
        }
    }
}



//updated works 


.details {
    border-top: 1px solid $black;
    padding: 1em 0;
    cursor: pointer;
    &:last-child {
        border-bottom: 1px solid $black;
    }
    @media screen and (max-width: $layout-breakpoints-small) {
        padding: 1em 0 2em;
    }

    &_summary {
        list-style: none; 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (max-width: $layout-breakpoints-small) {
            flex-direction: column;
        }
        

        .summary_title {
            font-family: $Amarante;
            font-size: 1.5rem;
        }

        .summary_type {
            font-family: $DarkGrotesque;
            font-size: 1rem;
            font-weight: 500;
        }
    }

    &_content {
        p:not(:first-child) {
            font-weight: 500;
            font-size: 0.875rem;
        }
    }

    &_link {
        display: flex;
        gap: 1em;
    }

    &_wrapper {
        transition: height ease 0.2s;
    }
}



.button-icon-work {
    padding-bottom: 0.5em;
    width: 28px;
    height: 28px;
    @media screen and (max-width: $layout-breakpoints-small) {
        width: 28px;
        height: 28px;
        padding: 0;
    }

}

//updated works 30 Jun 2023

.works-listing {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10px;
    max-height: 80vh;
    @media screen and (max-width: $layout-breakpoints-small) {
        display: flex;
        flex-wrap: nowrap;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding-top: 30px;
    }
    a {
        display: block;
        height: auto;
        width: 32.65%;
        @media screen and (max-width: $layout-breakpoints-small) {
            width: 55%;
            flex: 0 0 auto;
        }
        @media screen and (max-width: 565px) {
            width: 100%;
            flex: 0 0 auto;
        }
    }
}

.responsive {
    display: block;
    height: auto;
    width: 100%;
    // @media screen and (max-width: $layout-breakpoints-small) {
    //     width: 55%;
    //     flex: 0 0 auto;
    // }
    // @media screen and (max-width: 565px) {
    //     width: 100%;
    //     flex: 0 0 auto;
    // }
}


//updated works 18 Jul 2023
.item {
    min-height: 25rem;
    min-width: 25rem;
    padding: 10px;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: $layout-breakpoints-small) {
        max-height: 2rem;
        min-width: 19rem;
    }
}

.item img{
    width: 100%;
    height: auto;
    border-radius: 2rem;
    pointer-events: none;
    @media screen and (max-width: $layout-breakpoints-small) {
        height: 100%;
        width: auto;
    }
}

.inner-carousel {
    display: flex;
}

.carousel {
    overflow: hidden;
}

.work-title {
    white-space: nowrap;

    @media screen and (max-width: $layout-breakpoints-small) {
        font-size: 1.4rem;
        white-space: wrap;
        margin-bottom: 1rem;
    }
}